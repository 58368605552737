















































import { SetChatCommonConfigReq } from "@/includes/types/Chat/types";
import { isDeprecatedChatConfig } from "@/includes/types/Chat/predicates";

import { timezoneList } from "piramis-base-components/src/logic/timizone-list";
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue';
import getVSelectOptionByValue from 'piramis-js-utils/lib/getVSelectOptionByValue'
import { FieldData } from "piramis-base-components/src/components/Pi/types";

import { Component } from 'vue-property-decorator';
import Vue from "vue";

type LocalChatConfigFields = Pick<SetChatCommonConfigReq, 'timezone'>

@Component({
  data() {
    return {
      timezoneList
    }
  },
  components: {
    SelectInput
  }
})
export default class TimezoneSelect extends Vue {
  modelSetter(args: FieldData) {
    args.setter = (value) => {
      args.model[args.key] = value
    }

    return args
  }

  localModel: LocalChatConfigFields | null = null

  isTimezoneModalOpen = false

  get timezoneLabel() {
    if (this.chat) {
      return getVSelectOptionByValue(timezoneList, this.chat.timezone).label
    }

    return '-'
  }

  get chat() {
    return this.$store.state.chatState.chat
  }

  setTimezone() {
    if (isDeprecatedChatConfig(this.chat)) {
      this.$store.commit('EXEC_CALLBACK_IN_STORE', {
        'callback': () => this.$store.state.chatState.chat!.timezone = this.localModel!.timezone
      })
    }

    this.$store.dispatch('saveChatConfigRequest', this.localModel)
      .then(res => {
        this.isTimezoneModalOpen = false

        this.setLocalModel()
      })
  }

  setLocalModel() {
    if (this.chat) {
      this.localModel = {
        timezone: this.chat.timezone
      }
    }
  }

  created() {
    this.setLocalModel()
  }
}
